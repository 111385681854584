import { isNil } from "@angular-ru/cdk/utils";

import { IPeriod } from "@core/interfaces/period.interface";

import { BreachType } from "../../breach/enums/breach.enum";
import { DocumentType } from "../../documents/interfaces/documents.interface";
import { UserInvoiceType } from "../../invoices/admin/models/invoices.model";
import { InvoiceType } from "../../invoices/user/enums/invoice.enum";

export const API = {
    BASE: (controller = "/api") => `${controller}`,
    USER_META_DATA: () => `${API.BASE()}/userdata`,
    MENU_STATUS: () => `${API.BASE()}/route/status`,
    UPDATE_MAIN_LIMIT: () => `${API.BASE()}/user/limit`,
    HEALTH_CHECK: () => `${API.BASE()}/health`,
    AUTH: {
        BASE: () => `${API.BASE()}/auth`,
        LOGIN: () => `${API.AUTH.BASE()}/login`,
        INVITE: () => `${API.AUTH.BASE()}/invite`,
        REGISTRATION: () => `${API.AUTH.BASE()}/register`,
        RESTORE_PASSWORD: () => `${API.AUTH.BASE()}/restore`,
        RESET_PASSWORD: () => `${API.AUTH.BASE()}/reset`,
    },
    USERS: {
        BASE: () => `${API.BASE()}/user`,
        USERS_LIST: () => `${API.USERS.BASE()}/all`,
        SLAVE: (id: number) => `${API.USERS.BASE()}/${id}/slave`,
        SLAVES_LIST: (id: number) => `${API.USERS.BASE()}/${id}/slaves`,
        UPDATE_LIMIT: (id: number) => `${API.USERS.BASE()}/${id}/limit`,
        CHANGE_ALIAS: (id: number) => `${API.USERS.BASE()}/${id}/rename`,
        SUB_PARTNER: (id: number) => `${API.USERS.BASE()}/${id}/sub-partner`,
        SUB_PARTNERS_LIST: (id: number) => `${API.USERS.BASE()}/${id}/sub-partners`,
        CHANGE_USER_GROUP: (id: number) => `${API.USERS.BASE()}/${id}/group-change`,
    },
    INVITES: {
        BASE: () => `${API.BASE()}/invite`,
        GENERATE: () => `${API.INVITES.BASE()}/generate`,
    },
    AQUARIUMS: {
        BASE: () => `${API.USERS.BASE()}/firms`,
        LOCK: () => `${API.AQUARIUMS.BASE()}/lock`,
        UPDATE_ALL_FIRMS: () => `${API.AQUARIUMS.BASE()}/update-all`,
        UPDATE_FIRM: (id: number) => `${API.AQUARIUMS.BASE()}/${id}/update`,
        AVAILABLE: (id: number) => `${API.AQUARIUMS.BASE()}/${id}/tochno-access`,
    },
    EXTERNAL: {
        BASE: () => `${API.USERS.BASE()}/extfirms`,
        UPDATE_FIRM: (id: number) => `${API.EXTERNAL.BASE()}/${id}/update`,
    },
    BOOKS: {
        BASE: () => `${API.BASE()}/books`,
        BUYS: (inn: string, period: IPeriod, group: string) =>
            `${API.BOOKS.BASE()}/buy/${inn}/${period.quarter}/${period.year}/${group}`,
        SALES: (inn: string, period: IPeriod, group: string) =>
            `${API.BOOKS.BASE()}/sell/${inn}/${period.quarter}/${period.year}/${group}`,
        PAIRS: (inn: string, chainId: number) => `${API.BOOKS.BASE()}/pairs/${inn}/${chainId}`,
        CONTRAGENTS: (inn: string, period: IPeriod) =>
            `${API.BOOKS.BASE()}/contragents/${inn}/${period.quarter}/${period.year}`,
        CHAPTER12: (inn: string, chainId: number) =>
            `${API.BOOKS.BASE()}/chapter12/contragents/${inn}/${chainId}`,
        CHANGE_KPP: (inn: string) => `${API.BOOKS.BASE()}/change-kpp/${inn}`,
        CHANGE_CEO: (inn: string) => `${API.BOOKS.BASE()}/change-director/${inn}`,
        GET_HASH: () => `${API.BASE()}/docs/books/download`,
        FILE_PROCESSING_STATUS: (hash: string) => `${API.BASE()}/docs/books/status/${hash}`,
        DOWNLOAD: (hash: string) => `${API.BOOKS.GET_HASH()}/${hash}`,
        DOWNLOAD_JOURNAL: () => `${API.BOOKS.BASE()}/pairs/download`,
        DOWNLOAD_CHAPTER12: () => `${API.BOOKS.BASE()}/chapter12/download`,
    },
    CHECKER: {
        BASE: () => `${API.BASE()}/checker`,
        UPLOAD: () => `${API.CHECKER.BASE()}/upload`,
        UPDATE: () => `${API.CHECKER.BASE()}/update`,
        MULTI_UPDATE: () => `${API.CHECKER.BASE()}/multiUpdate`,
        SAVE: () => `${API.CHECKER.BASE()}/save`,
    },
    ORDERS: {
        BASE: () => `${API.BASE()}/orders`,
        DOWNLOAD: (hash: string) => `${API.ORDERS.BASE()}/download/${hash}`,
    },
    CONTROL: {
        BASE: () => `${API.BASE()}/orders`,
        TOTAL_SUM: () => `${API.CONTROL.BASE()}/total-sum`,
        UPDATE: () => `${API.CONTROL.BASE()}/update`,
        TABLE_DATA: (hash: string) => `${API.CONTROL.BASE()}/${hash}`,
        REMOVE_ROW: () => `${API.CONTROL.BASE()}/entry/delete`,
        REMOVE_REQUEST: () => `${API.CONTROL.BASE()}/delete`,
        SEARCH_BY_INN: () => `${API.CONTROL.BASE()}/search`,
        DOWNLOAD: (hash: string) => `${API.CONTROL.BASE()}/download/${hash}`,
    },
    SUB_PARTNERS: {
        BASE: () => `${API.BASE()}/submissive`,
        UPDATE_LIMIT: (id: number) => `${API.SUB_PARTNERS.BASE()}/${id}/limit`,
    },
    DOCUMENTS: {
        BASE: () => `${API.BASE()}/docs`,
        ADMIN_BASE: () => `${API.BASE()}/admin-docs`,
        ADMIN_FILES: (chainId: number) => `${API.DOCUMENTS.ADMIN_BASE()}/${chainId}`,
        ADMIN_DELETE_FILE: (type: DocumentType) => `${API.DOCUMENTS.ADMIN_BASE()}/delete/${type}`,
        ADMIN_SET_REQISITES_FLAG: () => `${API.DOCUMENTS.ADMIN_BASE()}/switch-flag`,
        ADMIN_UPLOAD_FILE: (type: DocumentType) => `${API.DOCUMENTS.ADMIN_BASE()}/upload/${type}`,
        TABLE_DATA: (hash: string) => `${API.CONTROL.BASE()}/${hash}`,
        DOWNLOAD: () => `/api/docs/download/books-user`,
    },
    DRESSING: {
        BASE: () => `${API.BASE()}/dressing`,
        BASE_ADMIN: () => `${API.BASE()}/admin-dressing`,

        ADMIN_MAIN: () => `${API.DRESSING.BASE_ADMIN()}/main`,
        ADMIN_TOCHNO: () => `${API.DRESSING.BASE_ADMIN()}/tochno`,

        ADMIN_TARIFFS: () => `${API.DRESSING.BASE_ADMIN()}/tariffs`,
        ADMIN_TARIFFS_UPDATE: (inn: string | number) =>
            `${API.DRESSING.BASE_ADMIN()}/sellers/${inn}/tariffs`,

        ADMIN_SETTINGS_MAIN: () => `${API.DRESSING.ADMIN_MAIN()}/settings`,
        ADMIN_SETTINGS_TOCHNO: () => `${API.DRESSING.ADMIN_TOCHNO()}/settings`,

        QUARTER_ACTIVITY_MAIN: () => `${API.DRESSING.ADMIN_SETTINGS_MAIN()}/quarter-activity`,
        QUARTER_ACTIVITY_TOCHNO: () => `${API.DRESSING.ADMIN_SETTINGS_TOCHNO()}/quarter-activity`,

        SWITCH_ALL_SALES: () => `${API.DRESSING.ADMIN_SETTINGS_TOCHNO()}/all-pause`,
        REPORT_DATE: () => `${API.DRESSING.ADMIN_SETTINGS_TOCHNO()}/report-date`,
        ORDER_CANCEL: () => `${API.DRESSING.ADMIN_TOCHNO()}/mark-new-order-cancel`,
        DOWNLOAD: () => `${API.DRESSING.ADMIN_TOCHNO()}/register`,
        CHECK_ALL_STATUS: () => `${API.DRESSING.ADMIN_SETTINGS_TOCHNO()}/all-pause`,

        SALES_PERIOD_MAIN: () => `${API.DRESSING.ADMIN_SETTINGS_MAIN()}/sales-period`,
        SALES_PERIOD_TOCHNO: () => `${API.DRESSING.ADMIN_SETTINGS_TOCHNO()}/sales-period`,

        CLIENT_TARIFFS: () => `${API.DRESSING.BASE()}/tariffs`,
    },
    INVOICES: {
        BASE: () => `${API.BASE()}/invoices`,
        BASE_ARCHIVE: () => `${API.INVOICES.BASE()}/archive`,
        USER_LIST: (period: IPeriod) => `${API.INVOICES.BASE()}/${period?.quarter}/${period?.year}`,
        ARCHIVE_USER_LIST: () => `${API.INVOICES.BASE_ARCHIVE()}/all`,
        TOTAL_SUMS: (period: IPeriod) =>
            `${API.INVOICES.BASE()}/totalSums/${period?.quarter}/${period?.year}`,
        ARCHIVE_TOTAL_SUMS: () => `${API.INVOICES.BASE_ARCHIVE()}/totalSums`,
        TOTAL_SUMS_ALL: (period: IPeriod) =>
            `${API.INVOICES.BASE()}/totalSumsAll/${period?.quarter}/${period?.year}`,
        DETAILED: (period: IPeriod) =>
            `${API.INVOICES.BASE()}/detailed/${period.quarter}/${period.year}`,
        DETAILED_TOTAL_SUMS: () => `${API.INVOICES.BASE_ARCHIVE()}/detailed`,
        DOWNLOAD: (period: IPeriod) =>
            `${API.INVOICES.BASE()}/detailed/download/${period.quarter}/${period.year}`,
        ARCHIVE_DOWNLOAD: () => `${API.INVOICES.BASE_ARCHIVE()}/detailed/download`,
        INVOICE_PERIOD: (type: InvoiceType, period: IPeriod) =>
            `${API.BASE()}/period/dates/${type}/${period.quarter}/${period.year}`,
        USER_INFO: (period: IPeriod, login: string) =>
            `${API.INVOICES.BASE()}/users-by-login/${login}/${period.quarter}/${period.year}`,
        ADMIN_BASE: () => `${API.BASE()}/admin-invoices`,
        ADMIN_USERS_LIGHT: (period: IPeriod, login?: string) =>
            `${API.INVOICES.ADMIN_BASE()}/users-for-hierarchy-by-login/${isNil(login) ? "" : login + "/"}${period.quarter}/${period.year}`,
        ADMIN_USERS: (period: IPeriod, login?: string) =>
            `${API.INVOICES.ADMIN_BASE()}/users-by-login/${isNil(login) ? "" : login + "/"}${period.quarter}/${period.year}`,
        ADMIN_USER_INVOICES: (type: UserInvoiceType, login: string, period: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/user-details/${type}/${login}/${period.quarter}/${period.year}`,
        ADMIN_USER_INVOICE_COMMENT: (orderID: string, period: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/comment/${orderID}/${period.quarter}/${period.year}`,
        ADMIN_USER_INVOICE_PERCENT: (period: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/order-percent/${period.quarter}/${period.year}`,
        ADMIN_USER_INVOICE_ROW_PERCENT: (hash: string, period: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/order-percent/${hash}/${period.quarter}/${period.year}`,
        ADMIN_USER_INVOICE_PENALTY: (hash: string, period: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/order-penalty-percent/${hash}/${period.quarter}/${period.year}`,
        ADMIN_USER_INVOICE_PRICE_LEVELS: (period: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/tariffs/levels/${period.quarter}/${period.year}`,
        ADMIN_USER_MANAGE_LEVEL: (login: string, { quarter, year }: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/users-by-login/${login}/levels/${quarter}/${year}`,
        ADMIN_USER_DOWNLOAD_INVOICE: (
            login: string,
            type: UserInvoiceType,
            { quarter, year }: IPeriod
        ) => `${API.INVOICES.ADMIN_BASE()}/users/${login}/download/${type}/${quarter}/${year}`,
        ADMIN_USER_GLOBAL_TOTALS: (type: UserInvoiceType, { quarter, year }: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/total-sum/${type}/${quarter}/${year}`,
        ADMIN_USER_GLOBAL_TOTALS_WIDTH_TARIFFS: ({ quarter, year }: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/total-sum/${quarter}/${year}`,
        ADMIN_USER_USER_TOTALS: (
            login: string,
            type: UserInvoiceType,
            { quarter, year }: IPeriod
        ) => `${API.INVOICES.ADMIN_BASE()}/user-sum-by-login/${login}/${type}/${quarter}/${year}`,
        ADMIN_USERS_SEARCH: (query: string) =>
            `${API.INVOICES.ADMIN_BASE()}/users/suggests/${query}`,
        ADMIN_USERS_PENALTY: ({ quarter, year }: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/penalties/${quarter}/${year}`,
        ADMIN_ORDERS_UPDATES: ({ quarter, year }: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/updates/${quarter}/${year}`,
        ADMIN_SUSPICIOUS_EVENTS: ({ quarter, year }: IPeriod) =>
            `${API.INVOICES.ADMIN_BASE()}/events/suspicious/${quarter}/${year}`,
        ADMIN_DISMISS_SUSPICIOUS_EVENT: (id: number) =>
            `${API.INVOICES.ADMIN_BASE()}/events/${id}/dismiss`,
    },
    BREACH: {
        BASE: () => `${API.BASE()}/breach`,
        ADMIN_BASE: () => `${API.BREACH.BASE()}/admin`,
        PERSONAL_SUBSTITUTIONS: () => `${API.BASE()}/begging/admin`,
        PERSONAL_SUBSTITUTIONS_DELETE: () =>
            `${API.BREACH.PERSONAL_SUBSTITUTIONS()}/remove-substitution`,
        PERSONAL_SUBSTITUTIONS_USERS: () =>
            `${API.BREACH.PERSONAL_SUBSTITUTIONS()}/filter-user-list`,
        PERSONAL_SUBSTITUTIONS_PERIOD: () => `${API.BREACH.PERSONAL_SUBSTITUTIONS()}/filter-user`,
        PERSONAL_SUBSTITUTIONS_FIRM: () => `${API.BREACH.PERSONAL_SUBSTITUTIONS()}/filter-period`,
        PERSONAL_SUBSTITUTIONS_ADD: () => `${API.BREACH.PERSONAL_SUBSTITUTIONS()}/add-substitution`,
        LIST: () => `${API.BREACH.BASE()}`,
        DOWNLOAD_VIEW: (type: BreachType) => `${API.BREACH.ADMIN_BASE()}/type-${type}/list`,
        GET_PRO_USERS: () => `${API.BREACH.ADMIN_BASE()}/list/pro-users`,
        SEARCH_PRO_USERS: () => `${API.BREACH.ADMIN_BASE()}/find/pro-users`,
        TOGGLE_PRO_STATUS: () => `${API.BREACH.ADMIN_BASE()}/update-user-pro-status`,
        DOWNLOAD_SELLERS: () => `${API.BREACH.ADMIN_BASE()}/download-sellers`,
        ADMIN_LOG: () => `${API.BREACH.ADMIN_BASE()}/log`,
        REPLACEMENT_LIST: () => `${API.BREACH.BASE()}/log/list`,
        ADMIN_REPLACEMENT_LIST: () => `${API.BREACH.ADMIN_LOG()}/list`,
        DELETE_ROW: () => `${API.BREACH.ADMIN_BASE()}/lost-back/delete`,
        SAVE_NEW_DATE: () => `${API.BREACH.ADMIN_BASE()}/lost-back/update-date`,
        READ_REPLACEMENT: () => `${API.BREACH.ADMIN_LOG()}/read`,
        LIST_OF_CLIENTS_FOR_ADMIN: () => `${API.BREACH.ADMIN_LOG()}/list/partners`,
        LIST_OF_CLIENTS_FOR_SPECIAL_ACCOUNT: () => `${API.BREACH.BASE()}/log/list/users`,
        CHANGE_DATE: () => `${API.BREACH.ADMIN_BASE()}/set-check-date`,
        UPLOAD: (type: BreachType) => `${API.BREACH.ADMIN_BASE()}/upload/${type}`,
        VIEW_APPLICATION: (hash: string) => `${API.BREACH.BASE()}/order/${hash}`,
        REPLACEMENT: (hash: string, id: string) => `${API.BREACH.VIEW_APPLICATION(hash)}/${id}`,
        GET_FIRMS: () => `${API.BREACH.BASE()}/firms`,
        SAVE: () => `${API.BREACH.BASE()}/save`,
        DISMISS: () => `${API.BREACH.BASE()}/dismiss`,
        REPLACE_FIRM: () => `${API.BREACH.GET_FIRMS()}/batch`,
        SAVE_FIRM: () => `${API.BREACH.SAVE()}/batch`,
        DOWNLOAD_REPLACEMENT: () => `${API.BREACH.REPLACEMENT_LIST()}/download`,
        READ_ALL: () => `${API.BREACH.REPLACEMENT_LIST()}/read-all`,
        DOWNLOAD_REPLACEMENT_ADMIN: () => `${API.BREACH.ADMIN_REPLACEMENT_LIST()}/download`,
        DETAILED: (inn: string, year: number, quarter: number) =>
            `${API.BREACH.BASE()}/group/${inn}/${quarter}/${year}`,
    },
    STATIC_FILE: {
        BASE: () => `${API.BASE()}/file`,
        BREACH_INSTRUCTION: (version: string | number) =>
            `${API.STATIC_FILE.BASE()}/get/Инструкция_по_модулю_Разрывы_V.${version}.pdf`,
        BUM_NDS_TEMPLATE: (version: string | number) =>
            `${API.STATIC_FILE.BASE()}/get/ЗАЯВКА_НА_БУМАЖНЫЙ_НДС_${version}.xlsx`,
        ONEC_HANDLER: () => `${API.STATIC_FILE.BASE()}/get/Обработчик_1С_для_покупателя.7z`,
    },
    NOTIFICATIONS: {
        BASE: () => `${API.BASE()}/notification`,
        TG_REGISTER: () => `${API.NOTIFICATIONS.BASE()}/register`,
    },
};
